<template>
    <div>
        <v-toolbar color="primary" dark class="toolbar">
            <v-row align="center">
                <v-col cols="12" md="6" class="d-flex">
                    <v-icon large>mdi-file-document-outline</v-icon>
                    <div class="ml-3">
                        <h4>{{ title }}</h4>
                        <h3>{{ titulo.ds_nosso_numero }}</h3>
                    </div>
                </v-col>
                <v-col class="d-flex justify-end" data-html2canvas-ignore>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                fab
                                dark
                                small
                                class="white--text"
                                @click="verificarFuncaoInstrumentos(titulo)"
                                :style="
                                    retornarValorInstrumentoProtesto(
                                        titulo,
                                        'style'
                                    )
                                "
                            >
                                <v-icon dark>
                                    {{
                                        retornarValorInstrumentoProtesto(
                                            titulo,
                                            'icon'
                                        )
                                    }}
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>
                            {{
                                retornarValorInstrumentoProtesto(
                                    titulo,
                                    'tooltip'
                                )
                            }}
                        </span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="problemaMensageria">
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on">
                                <v-icon large>mdi-email-alert-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Há problema de mensageria para este título</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn @click="copyLink()" icon v-on="on">
                                <v-icon large>mdi-link</v-icon>
                            </v-btn>
                        </template>
                        Copiar link deste título
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on">
                                <v-icon
                                    @click="imprimirTituloPDF(titulo)"
                                    v-if="!loadingTituloImpressao"
                                    large
                                    >mdi-printer-outline</v-icon
                                >
                                <v-progress-circular
                                    v-else
                                    dark
                                    indeterminate
                                ></v-progress-circular>
                            </v-btn>
                        </template>
                        <span>Imprimir este título</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-toolbar>
        <Snackbar
            v-if="snackbar"
            :mostrarSnackbar="snackbar"
            :corSnackbar="colorSnackbar"
            :mensagemSnackbar="mensagemSnackbar"
            @fecharSnackbar="snackbar = false"
        />
    </div>
</template>
<script>
import Vue from 'vue';
import TituloService from '@/services/TituloService';

export default {
    name: 'HeaderPageV2',
    props: {
        titulo: {
            type: Object,
            required: false
        },
        title: {
            type: String,
            required: true
        },
        problemaMensageria: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            snackbar: false,
            colorSnackbar: 'green',
            mensagemSnackbar: '',
            loadingTituloImpressao: false
        };
    },
    methods: {
        retornarValorInstrumentoProtesto(item, tipo) {
            const status_pre_protesto = [220, 221, 225];
            if (tipo === 'style') {
                if (item.cd_status === 102) {
                    if (!item.id_anexo_instrumento) {
                        return (
                            'background-color: ' +
                            this.$vuetify.theme.themes.light.primary
                        );
                    }
                    return 'background-color: green';
                }

                return 'background-color: #b8c4d4';
            }

            if (tipo === 'icon') {
                if (status_pre_protesto.includes(item.cd_status)) {
                    return 'mdi-clock-check-outline';
                }

                if (item.cd_status === 102) {
                    if (!item.id_anexo_instrumento) {
                        if (item.st_solicita_instrumento) {
                            return 'mdi-clock-check-outline';
                        }
                        return 'mdi-email-outline';
                    }
                    return 'mdi-download';
                }

                return 'mdi-close-circle-outline';
            }

            if (tipo === 'tooltip') {
                if (status_pre_protesto.includes(item.cd_status)) {
                    return 'Instrumento de Protesto Indisponível, aguardar Protesto';
                }

                if (item.cd_status === 102) {
                    if (!item.id_anexo_instrumento) {
                        if (item.st_solicita_instrumento) {
                            return 'Solicitação de Instrumento de Protesto já realizada, favor aguardar';
                        }
                        return 'Solicitar Instrumento de Protesto';
                    }
                    return 'Baixar Instrumento de Protesto';
                }

                return 'Instrumento de Protesto não está mais disponível.';
            }
        },
        verificarFuncaoInstrumentos(titulo) {
            if (
                titulo.cd_status === 102 &&
                !titulo.id_anexo_instrumento &&
                !titulo.st_solicita_instrumento
            ) {
                this.solicitarInstrumentos(titulo);
            }

            if (
                titulo.cd_status === 102 &&
                titulo.id_anexo_instrumento &&
                titulo.id_anexo_instrumento !== ''
            ) {
                this.baixarInstrumentos(titulo);
            }
        },
        baixarInstrumentos(titulo) {
            try {
                const tituloService = new TituloService(Vue.http, this.$store);
                this.loadingInicial = true;
                tituloService
                    .baixarInstrumentos(titulo)
                    .then(response => {
                        const url = window.URL.createObjectURL(
                            new Blob([response.data])
                        );
                        const link = document.createElement('a');
                        const nomeDocumento = `${titulo.ds_nosso_numero}_instrumento_protesto.pdf`;
                        link.href = url;
                        link.setAttribute('download', nomeDocumento);
                        document.body.appendChild(link);
                        link.click();
                    })
                    .catch(() => {
                        this.snackbarConsultaTitulo(
                            'error',
                            'Erro ao baixar Instrumento de Protesto'
                        );
                    })
                    .finally(() => {
                        this.loadingInicial = false;
                    });
            } catch (e) {
                this.snackbarConsultaTitulo(
                    'error',
                    'Erro ao baixar Instrumento de Protesto'
                );
                this.loadingInicial = false;
            }
        },
        solicitarInstrumentos(titulo) {
            try {
                const tituloService = new TituloService(Vue.http, this.$store);
                const titulo_id = titulo.id_titulo;
                let cd_apresentante = titulo.cd_apresentante;
                if (titulo.cd_comprador) {
                    cd_apresentante = titulo.cd_comprador;
                }

                this.loadingInicial = true;
                tituloService
                    .solicitaInstrumento(titulo_id, cd_apresentante)
                    .then(response => {
                        if (response.status == 200) {
                            titulo.st_solicita_instrumento = 1;
                            this.snackbarConsultaTitulo(
                                'success',
                                'Solicitação de Instrumento de Protesto realizada com sucesso, favor, aguardar'
                            );
                        }
                    })
                    .catch(() => {
                        this.snackbarConsultaTitulo(
                            'error',
                            'Erro ao solicitar Instrumento de Protesto'
                        );
                    })
                    .finally(() => {
                        this.loadingInicial = false;
                    });
            } catch (e) {
                this.snackbarConsultaTitulo(
                    'error',
                    'Erro ao solicitar Instrumento de Protesto'
                );
                this.loadingInicial = false;
            }
        },
        copyLink() {
            const el = document.createElement('textarea');
            el.value = window.location.href;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.mensagemSnackbar = 'Link copiado com sucesso!';
            this.snackbar = true;
            this.colorSnackbar = 'green';
        },
        imprimirTituloPDF() {
            this.$emit('imprimirTituloPDF');
        },
        tituloImpressao(titulo) {
            let tituloService = new TituloService(Vue.http, this.$store);
            this.loadingTituloImpressao = true;
            tituloService
                .tituloimpressao(titulo)
                .then(response => {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'título.pdf');
                    document.body.appendChild(link);
                    link.click();
                    this.loadingTituloImpressao = false;
                })
                .catch(() => {
                    this.loadingTituloImpressao = false;
                });
        }
    }
};
</script>
<style scoped>
.toolbar {
    border-radius: 8px;
}
</style>
