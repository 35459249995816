<template>
    <v-card class="pa-3">
        <v-row>
            <v-col>
                <h4 class="bold">Lista de arquivos Remessa/Confirmação</h4>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-list>
                    <v-list-item-group>
                        <v-list-item
                            v-for="(item, index) in arquivos"
                            :key="index"
                        >
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    item.nm_arquivo_cra
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                    >Status: {{ item.cd_status }} | Comarca:
                                    {{ item.cd_ibge_envio_comarca }} | UF:
                                    {{ item.cd_uf }}</v-list-item-subtitle
                                >
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn icon @click="downloadFile(item)">
                                    <v-icon>mdi-download</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
import TituloService from '@/services/TituloService';
import Vue from 'vue';
export default {
    props: {
        titulo: Object
    },
    data() {
        return {
            tituloService: new TituloService(Vue.http, this.$store),
            arquivos: []
        };
    },
    mounted() {
        this.getListFiles();
    },
    methods: {
        getListFiles() {
            let params = {
                cd_apresentante: this.titulo.cd_apresentante,
                id_titulo: this.titulo.id_titulo
            };
            this.tituloService
                .getListFiles(params)
                .then(response => {
                    this.arquivos = response;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        downloadFile(file) {
            let params = {
                cd_apresentante: this.titulo.cd_apresentante,
                ds_nosso_numero: this.titulo.ds_nosso_numero,
                nm_arquivo_cra: file.nm_arquivo_cra
            };
            this.tituloService
                .downloadFile(params)
                .then(response => {
                    const blob = new Blob([response.data], {
                        type: 'application/octet-stream'
                    });
                    const downloadUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.download = file.nm_arquivo_cra;
                    // link.download = 'arquivo';
                    document.body.appendChild(link);
                    link.click();
                    window.URL.revokeObjectURL(downloadUrl);
                    document.body.removeChild(link);
                })
                .catch(error => {
                    const uint8Array = new Uint8Array(error.data);
                    const decodedString = new TextDecoder().decode(uint8Array);
                    let error_data = JSON.parse(decodedString);

                    this.$emit('feedbackError', error_data['mensagem']);
                });
        }
    }
};
</script>
