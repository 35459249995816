var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "bg-view" },
    [
      !_vm.newTab
        ? _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.$router.go(-1)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                    1
                  ),
                  _c("span", [_vm._v("Voltar")])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.printEffect == "printEffect"
        ? _c(
            "v-card",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-progress-circular",
                {
                  staticClass: "ma-12",
                  attrs: { indeterminate: "", size: "150", color: "primary" }
                },
                [_vm._v("Imprimindo...")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.titulo.detalhe_titulo && !_vm.printEffect
        ? _c(
            "v-card",
            {
              staticClass: "pa-3",
              class: [_vm.printEffect, _vm.newTab ? "mt-0" : "mt-3"],
              attrs: { id: "page-layout" }
            },
            [
              _c(
                "v-row",
                {
                  staticStyle: { display: "none" },
                  attrs: { id: "container-logo", justify: "center" }
                },
                [
                  _c("v-col", { attrs: { align: "center" } }, [
                    _c("img", {
                      attrs: {
                        src: _vm.logo,
                        alt: "logo-print",
                        crossorigin: "anonymous",
                        width: "250px"
                      }
                    })
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0" },
                    [
                      _c("header-page-v2", {
                        attrs: {
                          title: "Detalhes do Título",
                          problemaMensageria: _vm.problemaMensageria,
                          titulo: _vm.titulo.detalhe_titulo
                        },
                        on: { imprimirTituloPDF: _vm.imprimirTituloPDF }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.alertaRevisao.length > 0
                ? _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "d-flex justify-center" }, [
                        _c(
                          "div",
                          { staticClass: "revisao-alerta" },
                          _vm._l(_vm.alertaRevisao, function(item) {
                            return _c("span", { key: item }, [
                              _vm._v(_vm._s(item))
                            ])
                          }),
                          0
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-end",
                      attrs: { "data-html2canvas-ignore": "" }
                    },
                    [
                      !_vm.usuarioLite && _vm.tituloIrregularNaoReapresentado
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                "data-cy": "btn-reapresentar-titulo",
                                dense: "",
                                color: "green white--text",
                                loading: _vm.loadingReapresentar
                              },
                              on: {
                                click: function($event) {
                                  _vm.reapresentacaoDialog = true
                                }
                              }
                            },
                            [
                              _vm._v(" Reapresentar "),
                              _c(
                                "v-icon",
                                { staticClass: "ml-2", attrs: { small: "" } },
                                [_vm._v("mdi-restart")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            dark: !_vm.computedPermitirAnuencia,
                            color: "#305AF0",
                            disabled:
                              _vm.computedPermitirAnuencia || _vm.isUserReadonly
                          },
                          on: {
                            click: function($event) {
                              return _vm.solicitaAnuencia(
                                _vm.titulo.detalhe_titulo
                              )
                            }
                          }
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-check-circle")]),
                          _vm._v(" Anuência ")
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            dark: !_vm.computedPermitirCancelamento,
                            color: "#FF4163",
                            disabled:
                              _vm.computedPermitirCancelamento ||
                              _vm.isUserReadonly
                          },
                          on: {
                            click: function($event) {
                              return _vm.solicitaCancelamento()
                            }
                          }
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-close-circle-outline")]),
                          _vm._v(" Cancelamento ")
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            dark: !_vm.computedPermitirDesistencia,
                            disabled:
                              _vm.computedPermitirDesistencia ||
                              _vm.isUserReadonly,
                            color: "#FF6E41"
                          },
                          on: {
                            click: function($event) {
                              return _vm.solicitaDesistencia()
                            }
                          }
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-close-thick")]),
                          _vm._v(" Desistência ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("card-informacoes-titulo", {
                        attrs: { titulo: _vm.titulo.detalhe_titulo },
                        on: {
                          alterarStatus: _vm.confirmarAlterarStatus,
                          reiniciarEnvio: _vm.confirmarReenvio
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("CardDevedor", {
                        attrs: { titulo: _vm.titulo.detalhe_titulo },
                        on: {
                          atualizaTitulo: function($event) {
                            return _vm.atualizarPagina()
                          },
                          editContacts: _vm.setAlterarContatosDevedor
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.titulo.detalhe_titulo.nu_quantidade_devedores > 1
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("CardCoodevedores", {
                            attrs: { titulo: _vm.titulo.detalhe_titulo },
                            on: {
                              atualizarTitulo: function($event) {
                                return _vm.atualizarPagina()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "align-self-stretch", attrs: { cols: "5" } },
                    [
                      _c("CardHistoricoStatus", {
                        attrs: { historico: _vm.titulo.historico_titulo },
                        on: {
                          atualizaTitulo: function($event) {
                            return _vm.atualizarPagina()
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("CardApontamento", {
                        attrs: { titulo: _vm.titulo.detalhe_titulo },
                        on: {
                          atualizaTitulo: function($event) {
                            return _vm.atualizarPagina()
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("CardInstrumentoProtesto", {
                        attrs: { titulo: _vm.titulo.detalhe_titulo },
                        on: {
                          atualizaTitulo: function($event) {
                            return _vm.atualizarPagina()
                          },
                          showSnackbar: _vm.setFeedbackSnackbar
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0" },
                    [
                      _c("CardAnexoProtesto", {
                        attrs: { titulo: _vm.titulo.detalhe_titulo },
                        on: {
                          atualizaTitulo: function($event) {
                            return _vm.atualizarPagina()
                          },
                          showSnackbar: _vm.setFeedbackSnackbar
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("CardSacadorCedenteApresentante", {
                        attrs: { titulo: _vm.titulo.detalhe_titulo }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("CardHistoricoEmails", {
                        attrs: {
                          titulo: _vm.titulo.detalhe_titulo,
                          listaEnvios: _vm.historicoEmails
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("CardComentariosTitulo", {
                        attrs: {
                          "data-html2canvas-ignore": "",
                          comentarios: _vm.comentarios,
                          id_titulo: parseInt(_vm.id_titulo)
                        },
                        on: {
                          atualizarComentarios: function($event) {
                            return _vm.getComentarios()
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "align-self-stretch" },
                    [
                      _c("CardMensageria", {
                        attrs: {
                          "data-html2canvas-ignore": "",
                          titulo: _vm.titulo.detalhe_titulo
                        },
                        on: { erroMensageria: _vm.erroMensageria }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("CardDownloadEnvios", {
                        attrs: { titulo: _vm.titulo.detalhe_titulo },
                        on: { feedbackError: msg => _vm.setFeedbackError(msg) }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800px" },
          model: {
            value: _vm.modalInformativoPreAnuencia,
            callback: function($$v) {
              _vm.modalInformativoPreAnuencia = $$v
            },
            expression: "modalInformativoPreAnuencia"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3" },
            [
              _c(
                "v-card-text",
                { staticClass: "ma-2 pa-2 font-weight-bold title" },
                [_vm._v("Deseja programar o envio da anuência?")]
              ),
              _c("v-card-text", { staticClass: "ma-2 pa-2" }, [
                _vm._v(
                  " O título/dívida ainda está com status “Apontado”. Portanto, o envio da anuência será programado para ser feito automaticamente logo após a formalização do protesto, ou seja, depois da intimação do devedor (presencial, carta com AR ou por edital) e da lavratura do instrumento de protesto. "
                )
              ]),
              _c("v-card-text", { staticClass: "ma-2 pa-2" }, [
                _vm._v(
                  " O Apresentante/Credor confirma que o devedor está ciente que: "
                )
              ]),
              _c("v-card-text", { staticClass: "ma-2 pa-2" }, [
                _vm._v(
                  " - Como condição da negociação/pagamento da dívida enviada a protesto, caberá ao devedor pagar os emolumentos diretamente ao cartório depois que o procedimento de protesto for concluído; "
                )
              ]),
              _c("v-card-text", { staticClass: "ma-2 pa-2" }, [
                _vm._v(
                  " - Ele(a) poderá receber uma intimação do cartório sobre o protesto; "
                )
              ]),
              _c("v-card-text", { staticClass: "ma-2 pa-2" }, [
                _vm._v(
                  " - A anuência eletrônica está programada e será enviada ao cartório apenas após a formalização do protesto. "
                )
              ]),
              _c("v-card-text", { staticClass: "ma-2 pa-2" }, [
                _vm._v(
                  " A Recuperi irá enviar e-mail(s) ao devedor no endereço de e-mail cadastrado pelo Apresentante/Credor com orientações sobre o procedimento de protesto, a programação do envio da anuência e pagamento de emolumentos. "
                )
              ]),
              _c("v-checkbox", {
                staticClass: "ma-2 pa-2",
                attrs: {
                  "data-cy": "anuenciaTermosCheckbox",
                  label: `O Apresentante/Credor autoriza a Recuperi a programar o envio de anuência.`
                },
                model: {
                  value: _vm.checkbox,
                  callback: function($$v) {
                    _vm.checkbox = $$v
                  },
                  expression: "checkbox"
                }
              }),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "green white--text",
                        disabled: !_vm.checkbox,
                        "data-cy": "confirmarPreAnuencia"
                      },
                      on: { click: _vm.confirmaCancelamento }
                    },
                    [_vm._v("Confirmar ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: { click: _vm.negaCancelamento }
                    },
                    [_vm._v("Cancelar")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          on: { "click:outside": _vm.negaCancelamento },
          model: {
            value: _vm.dialogConfirmationGeneric,
            callback: function($$v) {
              _vm.dialogConfirmationGeneric = $$v
            },
            expression: "dialogConfirmationGeneric"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline primary--text" }, [
                _vm._v(_vm._s(_vm.genericDialog.title))
              ]),
              _c("v-card-text", [
                _vm._v(" " + _vm._s(_vm.genericDialog.text) + " ")
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "green darken-1",
                        text: "",
                        "data-cy": "confirmaCancelamento"
                      },
                      on: { click: _vm.confirmaDialogGeneric }
                    },
                    [_vm._v(_vm._s(_vm.genericDialog.labelConfirmBtn) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: { click: _vm.cancelDialogGeneric }
                    },
                    [_vm._v(_vm._s(_vm.genericDialog.labelCancelBtn))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          on: { "click:outside": _vm.negaCancelamento },
          model: {
            value: _vm.dialogConfirmation,
            callback: function($$v) {
              _vm.dialogConfirmation = $$v
            },
            expression: "dialogConfirmation"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline primary--text" }, [
                _vm._v(_vm._s(_vm.tipo_cancelamento.substring(2)))
              ]),
              _vm.titulo.detalhe_titulo
                ? _c(
                    "v-card-text",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.dialogResponse,
                          expression: "!dialogResponse"
                        }
                      ]
                    },
                    [
                      _vm._v(
                        " Deseja realmente realizar " +
                          _vm._s(_vm.tipo_cancelamento) +
                          " do título " +
                          _vm._s(_vm.titulo.detalhe_titulo.ds_nosso_numero) +
                          "? " +
                          _vm._s(_vm.dialogExtra) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "v-card-text",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.dialogResponse,
                      expression: "dialogResponse"
                    }
                  ]
                },
                [_vm._v(_vm._s(_vm.dialogText))]
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.dialogResponse,
                          expression: "!dialogResponse"
                        }
                      ],
                      attrs: {
                        color: "green darken-1",
                        text: "",
                        "data-cy": "confirmaCancelamento"
                      },
                      on: { click: _vm.confirmaCancelamento }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.labelConfirm ? _vm.labelConfirm : "Sim") +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.dialogResponse,
                          expression: "!dialogResponse"
                        }
                      ],
                      attrs: { color: "red darken-1", text: "" },
                      on: { click: _vm.negaCancelamento }
                    },
                    [_vm._v("Não")]
                  ),
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.dialogResponse,
                          expression: "dialogResponse"
                        }
                      ],
                      attrs: {
                        color: "green darken-1",
                        text: "",
                        "data-cy": "confirmaResponse"
                      },
                      on: { click: _vm.fechaCancelamento }
                    },
                    [_vm._v("Ok! ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "ma-3 pa-3",
          attrs: {
            persistent: "",
            transition: "dialog-bottom-transition",
            "max-width": "1000px"
          },
          model: {
            value: _vm.dialogInfoDesistencia,
            callback: function($$v) {
              _vm.dialogInfoDesistencia = $$v
            },
            expression: "dialogInfoDesistencia"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-5 ma-4", attrs: { md: "7", lg: "7" } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "font-weight-black" },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "ma-2", attrs: { size: "50" } },
                            [_vm._v("mdi-alert-circle-outline")]
                          ),
                          _vm._v("Atenção ")
                        ],
                        1
                      ),
                      _c(
                        "v-card-title",
                        {
                          staticClass: "red--text display-1 font-weight-black"
                        },
                        [
                          _vm._v(
                            " A desistência foi solicitada após o prazo legal de 3 dias da data de envio do título para protesto. "
                          )
                        ]
                      ),
                      _c("v-card-text", {}, [
                        _vm._v(
                          " Caso o cartório responsável não aceite o pedido de desistência (de acordo com a Lei nº 9.492/1997), a Recuperi enviará automaticamente o comando de cancelamento assim que o devedor for efetivamente protestado. "
                        )
                      ]),
                      _c("v-card-text", {}, [
                        _vm._v(
                          " Importante: O comando de desistência gera custos de responsabilidade do apresentante. "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pa-5 ma-4", attrs: { md: "4", lg: "4" } },
                    [
                      _c("v-card-text", [
                        _vm._v("Saiba mais: "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "http://www.planalto.gov.br/ccivil_03/leis/l9492.htm",
                              target: "_blank"
                            }
                          },
                          [
                            _vm._v(
                              " http://www.planalto.gov.br/ccivil_03/leis/l9492.htm"
                            )
                          ]
                        )
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _c("v-icon", { staticClass: "ma-2" }, [
                            _vm._v("mdi-arrow-right")
                          ]),
                          _vm._v(
                            "Em caso de dúvida, por favor entre em contato com suporte@recuperi.com.br "
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-3 pa-3",
                          attrs: { color: "green white--text" },
                          on: { click: _vm.confirmaCancelamentoDesistencia }
                        },
                        [_vm._v("Ok, desejo continuar")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-3 pa-3",
                          attrs: { color: "red white--text" },
                          on: {
                            click: function($event) {
                              _vm.dialogInfoDesistencia = true
                            }
                          }
                        },
                        [_vm._v("Fechar")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "ma-3 pa-3" })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.modalAlterarContatosDevedor,
            callback: function($$v) {
              _vm.modalAlterarContatosDevedor = $$v
            },
            expression: "modalAlterarContatosDevedor"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline primary--text pl-0" },
                    [_vm._v("Alterar contatos do devedor")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-row",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              label: "E-mail do devedor",
                              required: ""
                            },
                            model: {
                              value: _vm.novosContatosdevedor.email,
                              callback: function($$v) {
                                _vm.$set(_vm.novosContatosdevedor, "email", $$v)
                              },
                              expression: "novosContatosdevedor.email"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "(##) #####-####",
                                expression: "'(##) #####-####'"
                              }
                            ],
                            attrs: {
                              outlined: "",
                              dense: "",
                              label: "Telefone do devedor",
                              required: ""
                            },
                            model: {
                              value: _vm.novosContatosdevedor.celular,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.novosContatosdevedor,
                                  "celular",
                                  $$v
                                )
                              },
                              expression: "novosContatosdevedor.celular"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { dense: "", color: "red white--text", text: "" },
                      on: {
                        click: function($event) {
                          _vm.modalAlterarContatosDevedor = false
                        }
                      }
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: !_vm.validateContatosDevedor,
                        dense: "",
                        color: "green darken-1",
                        text: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.alterarContatoDevedor()
                        }
                      }
                    },
                    [_vm._v("Salvar")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            transition: "dialog-bottom-transition",
            "max-width": "1100px"
          },
          model: {
            value: _vm.reapresentacaoDialog,
            callback: function($$v) {
              _vm.reapresentacaoDialog = $$v
            },
            expression: "reapresentacaoDialog"
          }
        },
        [
          _c("reapresentacao-titulo", {
            attrs: { titulo: _vm.titulo.detalhe_titulo },
            on: {
              fecharModal: function($event) {
                _vm.reapresentacaoDialog = false
              },
              reloadTable: _vm.recarregarTitulo
            }
          })
        ],
        1
      ),
      _vm.feedbackSnackbar
        ? _c("Snackbar", {
            attrs: {
              "data-html2canvas-ignore": "",
              mostrarSnackbar: _vm.feedbackSnackbar,
              corSnackbar: _vm.feedbackSnackbarColor,
              mensagemSnackbar: _vm.mensagemfeedbackSnackbar
            },
            on: {
              fecharSnackbar: function($event) {
                _vm.feedbackSnackbar = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }