<template>
    <v-card class="pa-3">
        <v-row>
            <v-col cols="6">
                <h4 class="bold">Sacador</h4>
            </v-col>
            <v-col>
                <h4 class="bold">Cedente</h4>
            </v-col>
            <v-col class="d-flex justify-end">
                <!-- <select-actions :actions="[{name: 'Editar Dados', method: 'editarApontamento'}]"/> -->
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4" class="py-1">
                <v-text-field
                    dense
                    outlined
                    :value="titulo.nm_sacador"
                    label="Nome do sacador"
                    readonly
                    hide-details="auto"
                ></v-text-field>
            </v-col>
            <v-col class="py-1">
                <v-text-field
                    outlined
                    dense
                    :value="titulo.cnpj_sacador"
                    v-mask="['XX.XXX.XXX/XXXX-##', '###.###.###-##']"
                    label="CNPJ"
                    readonly
                    hide-details="auto"
                ></v-text-field>
            </v-col>
            <v-col cols="6" class="py-1">
                <v-text-field
                    outlined
                    dense
                    :value="titulo.nm_cedente_favorecido"
                    label="Nome do cedente favorecido"
                    readonly
                    hide-details="auto"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col cols="6">
                <h4 class="bold">Apresentante</h4>
            </v-col>
        </v-row>
        <v-row class="pb-3">
            <v-col cols="4" class="py-1">
                <v-text-field
                    outlined
                    dense
                    :value="titulo.nm_razao_social"
                    label="Razão social"
                    readonly
                    hide-details="auto"
                ></v-text-field>
            </v-col>
            <v-col cols="2" class="py-1">
                <v-text-field
                    outlined
                    dense
                    :value="titulo.cd_apresentante"
                    label="Código"
                    hide-details="auto"
                    readonly
                ></v-text-field>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
// import SelectActions from '../atoms/SelectActions.vue';
import moment from 'moment';
export default {
    name: 'CardApontamento',
    components: {
        // SelectActions
    },
    props: {
        titulo: {
            type: Object,
            required: true
        }
    },
    created() {
        moment.locale('pt-br');
    },
    methods: {
        formatDate(date) {
            return moment(date).format('DD/MM/YYYY');
        }
    }
};
</script>
