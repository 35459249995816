<template>
    <v-card class="px-3 pb-0">
        <ConfirmacaoModal
            v-if="mostrarModalConfirmacao"
            :showDialog="mostrarModalConfirmacao"
            :tituloModal="tituloModalConfirmacao"
            :textoModal="textoModalConfirmacao"
            :btnConfirmarEmit="btnConfirmarModalConfirmacao"
            :esconderCancelar="false"
            :btnConfirmar="btnConfirmarLabel"
            @invalidarInstrumento="invalidarInstrumento"
            @fecharModal="
                mostrarModalConfirmacao = false;
                esconderCancelar = false;
            "
        />
        <v-row>
            <v-col>
                <h4 class="bold">Informações do Título</h4>
            </v-col>
            <v-col class="d-flex justify-end">
                <select-actions
                    @executeAction="callMethod"
                    :actions="[
                        {
                            name: 'Irregularizar: Cartório inativo',
                            method: 'irregularizarTitulo',
                            disabled: !statusParaIrregularizarTitulo.includes(
                                titulo.cd_status
                            )
                        },
                        {
                            name: 'Irregularizar: Erro do cartório',
                            method: 'irregularizarTituloK3',
                            disabled: !statusParaIrregularizarTituloK3.includes(
                                titulo.cd_status
                            )
                        },
                        {
                            name: 'Enviar a Negociação',
                            method: 'enviarNegociacao',
                            disabled: !statusParaRenegociarProtestar.includes(
                                titulo.cd_status
                            )
                        },
                        {
                            name: 'Enviar a Protesto',
                            method: 'enviarProtesto',
                            disabled: !statusParaRenegociarProtestar.includes(
                                titulo.cd_status
                            )
                        },
                        {
                            name: 'Reiniciar Envio',
                            method: 'reiniciarEnvio',
                            disabled: desabilitaBotaoFila
                        },
                        {
                            name: 'Invalidar Instrumento de Protesto',
                            method: 'modalInvalidarInstrumento',
                            disabled: !instrumentoProtesto
                        }
                    ]"
                />
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <v-col class="pb-3">
                <v-text-field
                    outlined
                    dense
                    :value="titulo.cd_status + ' - ' + titulo.ds_status"
                    label="Status"
                    readonly
                    hide-details="auto"
                ></v-text-field>
            </v-col>
            <v-col class="pb-3" cols="2">
                <v-text-field
                    outlined
                    dense
                    :value="titulo.id_titulo"
                    label="ID"
                    readonly
                    hide-details="auto"
                ></v-text-field>
            </v-col>
            <v-col class="pb-3" cols="2">
                <v-text-field
                    outlined
                    dense
                    :value="titulo.ds_numero_titulo"
                    label="Número do Título"
                    readonly
                    hide-details="auto"
                ></v-text-field>
            </v-col>
            <v-col class="pb-3" cols="2">
                <v-text-field
                    outlined
                    dense
                    :value="titulo.nu_parcela"
                    label="Parcela"
                    hide-details="auto"
                    readonly
                ></v-text-field>
            </v-col>
            <v-col class="pb-3" cols="2">
                <v-text-field
                    outlined
                    dense
                    :value="titulo.ds_nosso_numero"
                    label="Nosso Número"
                    hide-details="auto"
                    readonly
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col class="pb-3">
                <v-text-field
                    outlined
                    dense
                    :value="'R$ ' + titulo.vl_saldo_protesto_formatado"
                    label="Valor Protestado"
                    readonly
                    hide-details="auto"
                ></v-text-field>
            </v-col>
            <v-col class="pb-3">
                <v-text-field
                    outlined
                    dense
                    :value="'R$' + titulo.vl_titulo_formatado"
                    label="Valor total do Título"
                    readonly
                    hide-details="auto"
                ></v-text-field>
            </v-col>
            <v-col class="pb-3">
                <v-text-field
                    outlined
                    dense
                    :value="formatDate(titulo.dt_emissao)"
                    label="Data de emissão"
                    hide-details="auto"
                    readonly
                ></v-text-field>
            </v-col>
            <v-col class="pb-3">
                <v-text-field
                    outlined
                    dense
                    :value="formatDate(titulo.dt_vencimento)"
                    label="Data de vencimento"
                    hide-details="auto"
                    readonly
                ></v-text-field>
            </v-col>
            <v-col class="pb-3">
                <v-text-field
                    outlined
                    dense
                    :value="'R$ ' + (titulo.vl_repassado_formatado || '0,00')"
                    label="Valor Repasse"
                    hide-details="auto"
                    readonly
                ></v-text-field>
            </v-col>
            <v-col class="pb-3">
                <v-text-field
                    outlined
                    dense
                    :value="'R$ ' + (titulo.vl_custas_formatado || '0,00')"
                    label="Custa Cancelamento"
                    hide-details="auto"
                    readonly
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col class="pb-3">
                <v-text-field
                    outlined
                    dense
                    :value="titulo.cd_especie_titulo"
                    label="Espécie"
                    hide-details="auto"
                    readonly
                ></v-text-field>
            </v-col>
            <v-col class="pb-3">
                <v-text-field
                    outlined
                    dense
                    :value="titulo.tp_declaracao"
                    label="Declaração"
                    hide-details="auto"
                    readonly
                ></v-text-field>
            </v-col>
            <v-col class="pb-3">
                <v-text-field
                    outlined
                    dense
                    :value="titulo.tp_endosse || ' '"
                    label="Endosso"
                    hide-details="auto"
                    readonly
                ></v-text-field>
            </v-col>
            <v-col class="pb-3">
                <v-text-field
                    outlined
                    dense
                    :value="titulo.tp_aceite"
                    label="Aceite"
                    hide-details="auto"
                    readonly
                ></v-text-field>
            </v-col>
            <v-col class="pb-3">
                <v-text-field
                    outlined
                    dense
                    :value="titulo.cd_falimentar || '  '"
                    label="Falimentar"
                    hide-details="auto"
                    readonly
                ></v-text-field>
            </v-col>
            <v-col class="pb-3">
                <v-text-field
                    outlined
                    dense
                    :value="titulo.ds_agrupador"
                    label="Agrupador"
                    hide-details="auto"
                    readonly
                ></v-text-field>
            </v-col>
        </v-row>
        <Snackbar
            data-html2canvas-ignore
            v-if="feedbackSnackbar"
            :mostrarSnackbar="feedbackSnackbar"
            :corSnackbar="feedbackSnackbarColor"
            :mensagemSnackbar="mensagemfeedbackSnackbar"
            @fecharSnackbar="feedbackSnackbar = false"
        />
    </v-card>
</template>
<script>
import SelectActions from '../atoms/SelectActions.vue';
import TituloService from '@/services/TituloService';
import ConfirmacaoModal from '@/modals/ConfirmacaoModal';
import Vue from 'vue';
import { getMensagemRequest } from '@/helpers/Utilitarios';
import moment from 'moment';
export default {
    name: 'CardInformacoesTitulo',
    components: {
        SelectActions,
        ConfirmacaoModal
    },
    props: {
        titulo: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            statusParaIrregularizarTitulo: [220, 221, 225],
            statusParaIrregularizarTituloK3: [220, 221, 225, 230, 252, 250],
            statusParaRenegociarProtestar: [304, 305, 303],
            desabilitaBotaoFila: true,
            dt_ontem: moment()
                .subtract(1, 'days')
                .format('YYYY-MM-DD'),
            tituloService: new TituloService(Vue.http, this.$store),
            loading: false,
            mostrarModalConfirmacao: false,
            tituloModalConfirmacao: '',
            textoModalConfirmacao: '',
            btnConfirmarLabel: '',
            btnConfirmarModalConfirmacao: '',
            instrumentoProtesto: null,
            feedbackSnackbarTimeout: 5000,
            feedbackSnackbarColor: '',
            mensagemfeedbackSnackbar: '',
            feedbackSnackbar: false
        };
    },
    created() {
        moment.locale('pt-br');
    },
    mounted() {
        this.buscarInstrumentoProtesto();
    },
    methods: {
        getMensagemRequest,
        setFeedbackSnackbar(color, message) {
            this.feedbackSnackbarColor = color;
            this.mensagemfeedbackSnackbar = message;
            this.feedbackSnackbar = true;
        },
        modalInvalidarInstrumento() {
            this.tituloModalConfirmacao = 'Invalidar Instrumento de Protesto';
            this.textoModalConfirmacao =
                'Após invalidar um Instrumento de Protesto, ele ficará indisponível permanentemente e será necessário realizar uma nova solicitação. <br /><br />Deseja prosseguir?';
            this.btnConfirmarLabel = 'Confirmar';
            this.btnConfirmarModalConfirmacao = 'invalidarInstrumento';
            this.mostrarModalConfirmacao = true;
        },
        buscarInstrumentoProtesto() {
            this.tituloService
                .buscarAnexoTitulo(this.titulo, 'IP')
                .then(
                    response => {
                        if (Object.keys(response.body).length > 0) {
                            this.instrumentoProtesto = response.body;
                        } else {
                            this.instrumentoProtesto = null;
                        }
                    },
                    error => {
                        console.log(error);
                        this.setFeedbackSnackbar(
                            'error',
                            'Erro ao buscar Instrumento de Protesto'
                        );
                    }
                )
                .finally(() => {
                    this.loadingInstrumento = false;
                });
        },
        async invalidarInstrumento() {
            try {
                this.loading = true;
                this.mostrarModalConfirmacao = false;
                await this.tituloService.invalidarInstrumento(this.titulo);

                this.setFeedbackSnackbar(
                    'success',
                    'Instrumento de Protesto invalidado com sucesso!'
                );
                this.exibirDetalhe();
                this.buscarInstrumentoProtesto();
            } catch (e) {
                const mensagem = this.getMensagemRequest(
                    e.data,
                    'Erro ao invalidar Instrumento de Protesto'
                );
                this.setFeedbackSnackbar('error', mensagem);
            } finally {
                this.loading = false;
            }
        },
        formatDate(date) {
            return moment(date).format('DD/MM/YYYY');
        },
        callMethod(method) {
            method === 'irregularizarTitulo' &&
                this.$emit('alterarStatus', 105, 'K1');
            method === 'irregularizarTituloK3' &&
                this.$emit('alterarStatus', 105, 'K3');
            method === 'enviarNegociacao' && this.$emit('alterarStatus', 320);
            method === 'enviarProtesto' && this.$emit('alterarStatus', 220);
            method === 'reiniciarEnvio' && this.$emit('reiniciarEnvio');
            method === 'modalInvalidarInstrumento' &&
                this.modalInvalidarInstrumento();
        },
        verificaStEnvio() {
            if (this.titulo.st_envio === -1 || this.titulo.st_envio === 1) {
                if (this.titulo.dt_atualizacao > this.dt_ontem) {
                    //aqui habilita o botão
                    this.desabilitaBotaoFila = false;
                } else {
                    this.desabilitaBotaoFila = true;
                }
            } else {
                this.desabilitaBotaoFila = true;
            }
        }
    }
};
</script>
