<template>
    <v-card class="pa-3">
        <v-row>
            <v-col>
                <h4 class="bold">Comentarios do Título</h4>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <span v-if="!comentarios.length"
                    >Não há comentarios neste título</span
                >
                <v-list v-else>
                    <v-list-item-group>
                        <v-list-item
                            v-for="(comentario, index) in comentarios"
                            :key="index"
                            class="bg-comentario"
                        >
                            <v-list-item-content>
                                <v-list-item-title>
                                    <strong>
                                        {{ comentario.email_user }}(ID:
                                        {{ comentario.id_user }}/{{
                                            comentario.name_user
                                        }}) - Criado em
                                        {{
                                            FormateData(comentario.dt_criacao)
                                        }}:
                                        <i class="text-gray">{{
                                            comentario.comentario
                                        }}</i>
                                    </strong>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-col>
        </v-row>
        <v-row v-if="addComentario">
            <v-col>
                <v-textarea
                    label="Novo comentario"
                    outlined
                    v-model="novoComentario"
                    placeholder="Escreva aqui seu comentario"
                >
                </v-textarea>
            </v-col>
        </v-row>
        <v-row v-if="addComentario">
            <v-col class="d-flex justify-end">
                <v-btn color="red" dark @click="addComentario = false">
                    <v-icon>mdi-close</v-icon>
                    Cancelar
                </v-btn>
                <v-btn
                    :loading="loadingSave"
                    color="success"
                    class="ml-3"
                    @click="criarComentario()"
                >
                    <v-icon>mdi-check</v-icon>
                    Salvar
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-if="!addComentario">
            <v-col class="d-flex justify-end">
                <v-btn @click="addComentario = true">
                    <v-icon>mdi-plus</v-icon>
                    Adicionar comentario
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
import moment from 'moment';
import TituloService from '@/services/TituloService';
import Vue from 'vue';

export default {
    name: 'CardComentariosTitulo',
    props: {
        comentarios: {
            type: Array,
            default: () => []
        },
        id_titulo: {
            type: Number,
            default: () => null
        }
    },
    data() {
        return {
            addComentario: false,
            novoComentario: '',
            tituloService: new TituloService(Vue.http, this.$store),
            loadingSave: false
        };
    },
    methods: {
        FormateData(data) {
            return moment(data).format('DD/MM/YYYY');
        },
        criarComentario() {
            this.loadingSave = true;
            this.tituloService
                .criarComentario(this.novoComentario, this.id_titulo)
                .then(() => {
                    this.novoComentario = '';
                    this.addComentario = false;
                    this.loadingSave = false;
                    this.$emit('atualizarComentarios');
                })
                .catch(error => {
                    this.loadingSave = false;
                    console.log(error);
                });
        }
    },
    mounted() {}
};
</script>

<style lang="scss" scoped>
.text-gray {
    color: #666;
    font-weight: 400;
}
.bg-comentario {
    background-color: #f5f5f5;
    border-radius: 5px;
    margin-bottom: 5px;
}
.v-list {
    max-height: 220px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 6px; /* Largura da barra de rolagem */
    }

    /* Personalizar o fundo da barra de rolagem (parte que não se move) */
    &::-webkit-scrollbar-track {
        margin-left: 10px;
        background: #fff; /* Cor do fundo */
    }

    /* Personalizar a barra de rolagem móvel */
    &::-webkit-scrollbar-thumb {
        background: #888; /* Cor da barra de rolagem */
        border-radius: 5px; /* Border radius da barra de rolagem */
    }

    /* Personalizar a barra de rolagem quando o mouse está sobre ela */
    &::-webkit-scrollbar-thumb:hover {
        background: #555; /* Cor da barra de rolagem quando o mouse está sobre ela */
    }
}
</style>
