<template>
    <div>
        <ConfirmacaoModal
            v-if="mostrarModalConfirmacao"
            :showDialog="mostrarModalConfirmacao"
            :tituloModal="tituloModalConfirmacao"
            :textoModal="textoModalConfirmacao"
            :btnConfirmarEmit="btnConfirmarModalConfirmacao"
            :esconderCancelar="esconderCancelar"
            :btnConfirmar="btnConfirmarLabel"
            @invalidarInstrumento="invalidarInstrumento"
            @fecharModal="
                mostrarModalConfirmacao = false;
                esconderCancelar = false;
            "
        />
        <v-card class="pa-3 card-instrumento">
            <v-row>
                <v-col>
                    <h4 class="bold">Instrumento de Protesto</h4>
                </v-col>
            </v-row>
            <v-row v-if="!instrumentoProtesto" class="d-flex h-90">
                <v-col
                    sm="12"
                    md="12"
                    lg="12"
                    class="d-flex justify-center align-center h-90"
                >
                    <v-alert
                        prominent
                        :icon="getIconeAlerta()"
                        shaped
                        :type="getTipoAlerta()"
                    >
                        {{ getTextoAlerta() }}
                        <v-btn
                            v-if="permitirSolicitarInstrumento()"
                            @click="solicitaInstrumentoProt"
                            color="#46a6f5"
                            fab
                            dark
                            small
                            class="white--text ml-2"
                        >
                            <v-icon class="ma-2"
                                >mdi-arrow-top-right-bold-box-outline</v-icon
                            >
                        </v-btn>
                    </v-alert>
                </v-col>
            </v-row>
            <v-row v-else class="d-flex align-center mt-0 py-3">
                <v-col cols="12" sm="4" md="4" class="py-0">
                    <v-text-field
                        outlined
                        dense
                        v-model="instrumentoProtesto.ds_anexo_nome"
                        label="Nome Anexo"
                        required
                        readonly
                        hide-details
                    ></v-text-field>
                </v-col>
                <v-btn
                    class="ml-3 white--text"
                    @click="baixarInstrumentos"
                    :loading="baixandoInstrumento"
                    color="green"
                    width="120px"
                    >BAIXAR
                </v-btn>
                <v-btn
                    v-if="superUsuario"
                    class="ml-3 white--text"
                    @click="modalInvalidarInstrumento"
                    :loading="baixandoInstrumento"
                    color="orange darken-3"
                    width="120px"
                    >INVALIDAR
                </v-btn>
            </v-row>
        </v-card>
    </div>
</template>
<script>
import TituloService from '@/services/TituloService';
import ConfirmacaoModal from '@/modals/ConfirmacaoModal';
import Vue from 'vue';
import { getMensagemRequest } from '../../helpers/Utilitarios';

export default {
    name: 'CardInstrumentoProtesto',
    props: {
        titulo: {
            type: Object,
            required: true
        }
    },
    components: {
        ConfirmacaoModal
    },
    data() {
        return {
            instrumentoProtesto: null,
            loadingInstrumento: false,
            baixandoInstrumento: false,
            mostrarModalConfirmacao: false,
            tituloModalConfirmacao: '',
            textoModalConfirmacao: '',
            btnConfirmarLabel: 'Confirmar',
            btnConfirmarModalConfirmacao: '',
            esconderCancelar: false
        };
    },
    mounted() {
        this.buscarInstrumentoProtesto();
    },
    computed: {
        superUsuario() {
            if (this.$store.getters.getSuperUser == 1) {
                return true;
            }

            return false;
        }
    },
    methods: {
        getMensagemRequest,
        baixarInstrumentos() {
            const tituloService = new TituloService(Vue.http, this.$store);
            this.baixandoInstrumento = true;
            tituloService
                .baixarInstrumentos(this.titulo)
                .then(
                    response => {
                        const url = window.URL.createObjectURL(
                            new Blob([response.data])
                        );
                        const link = document.createElement('a');
                        const nomeDocumento = `${this.titulo.ds_nosso_numero}_instrumento_protesto.pdf`;
                        link.href = url;
                        link.setAttribute('download', nomeDocumento);
                        document.body.appendChild(link);
                        link.click();
                    },
                    error => {
                        console.log(error);
                        this.emitirSnackbar(
                            'error',
                            'Erro ao baixar Instrumento de Protesto'
                        );
                    }
                )
                .finally(() => {
                    this.baixandoInstrumento = false;
                });
        },
        modalInvalidarInstrumento() {
            this.tituloModalConfirmacao = 'Invalidar Instrumento de Protesto';
            this.textoModalConfirmacao =
                'Após invalidar um Instrumento de Protesto, ele ficará indisponível permanentemente e será necessário realizar uma nova solicitação. <br /><br />Deseja prosseguir?';
            this.btnConfirmarLabel = 'Confirmar';
            this.btnConfirmarModalConfirmacao = 'invalidarInstrumento';
            this.mostrarModalConfirmacao = true;
        },
        solicitaInstrumentoProt() {
            if (this.titulo.cd_status === 102) {
                this.loadingInstrumento = true;
                this.solicitaInstrumento = true;
                const tituloService = new TituloService(Vue.http, this.$store);
                let titulo = this.titulo.id_titulo;
                let cd_apresentante = this.titulo.cd_apresentante;
                if (this.titulo.cd_comprador) {
                    cd_apresentante = this.titulo.cd_comprador;
                }
                tituloService
                    .solicitaInstrumento(titulo, cd_apresentante)
                    .then(response => {
                        this.loadingInstrumento = false;
                        this.$emit('atualizaTitulo');
                        if (response.status == 200) {
                            this.titulo.st_solicita_instrumento = 1;
                        }
                    })
                    .catch(() => {
                        this.emitirSnackbar(
                            'error',
                            'Erro ao solicitar Instrumentos de Protesto'
                        );
                    })
                    .finally(() => {
                        this.loadingInstrumento = false;
                        this.solicitaInstrumento = false;
                    });
            }
        },
        getTextoAlerta() {
            const status_pre_protesto = [220, 221, 225];

            if (status_pre_protesto.includes(this.titulo?.cd_status)) {
                return 'Instrumento de Protesto Indisponível, aguardar Protesto';
            }

            if (this.titulo?.cd_status === 102) {
                if (this.solicitaInstrumento) {
                    return 'Solicitando Instrumento de Protesto...';
                }

                if (this.loadingInstrumento) {
                    return 'Buscando Instrumento de Protesto...';
                }

                if (this.titulo?.st_solicita_instrumento) {
                    return 'Solicitação de Instrumento de Protesto já realizada, favor aguardar';
                } else {
                    return 'Solicitar Instrumento de Protesto';
                }
            }

            return 'Instrumento de Protesto não está mais disponível.';
        },
        buscarInstrumentoProtesto() {
            this.loadingInstrumento = true;
            let titulo = this.titulo;
            const tituloService = new TituloService(Vue.http, this.$store);
            tituloService
                .buscarAnexoTitulo(titulo, 'IP')
                .then(
                    response => {
                        if (Object.keys(response.body).length > 0) {
                            this.instrumentoProtesto = response.body;
                        } else {
                            this.instrumentoProtesto = null;
                        }
                    },
                    error => {
                        console.log(error);
                        this.emitirSnackbar(
                            'error',
                            'Erro ao buscar Instrumento de Protesto'
                        );
                    }
                )
                .finally(() => {
                    this.loadingInstrumento = false;
                });
        },
        getIconeAlerta() {
            const status_pre_protesto = [220, 221, 225];

            if (status_pre_protesto.includes(this.titulo?.cd_status)) {
                return 'mdi-clock-check-outline';
            }

            if (this.titulo?.cd_status === 102) {
                if (this.solicitaInstrumento) {
                    return 'mdi-sync';
                }

                if (this.loadingInstrumento) {
                    return 'mdi-magnify';
                }

                if (this.titulo?.st_solicita_instrumento) {
                    return 'mdi-check-circle-outline';
                } else {
                    return 'mdi-information-outline';
                }
            }

            return 'mdi-close-circle-outline';
        },
        getTipoAlerta() {
            if (
                ![220, 221, 225, 102].includes(this.titulo?.cd_status) &&
                !this.instrumentoProtesto
            ) {
                return 'error';
            }
            if (
                [220, 221, 225].includes(this.titulo?.cd_status) &&
                !this.instrumentoProtesto
            ) {
                return 'warning';
            }
            if (
                [102, 109, 123].includes(this.titulo?.cd_status) &&
                !this.instrumentoProtesto
            ) {
                return 'info';
            }
        },
        permitirSolicitarInstrumento() {
            if (
                !this.loadingInstrumento &&
                this.titulo &&
                !this.titulo.st_solicita_instrumento &&
                this.titulo.cd_status === 102
            ) {
                return true;
            }
            return false;
        },
        async invalidarInstrumento() {
            try {
                this.loading = true;
                this.mostrarModalConfirmacao = false;
                const tituloService = new TituloService(Vue.http, this.$store);
                await tituloService.invalidarInstrumento(this.titulo);

                this.emitirSnackbar(
                    'green',
                    'Instrumento de Protesto invalidado com sucesso!'
                );
                this.$emit('atualizaTitulo');
                this.buscarInstrumentoProtesto();
            } catch (e) {
                const mensagem = this.getMensagemRequest(
                    e.data,
                    'Erro ao invalidar Instrumento de Protesto'
                );
                this.emitirSnackbar('error', mensagem);
            } finally {
                this.loading = false;
            }
        },
        emitirSnackbar(snackbarColor, mensagemSnackBar) {
            this.$emit('showSnackbar', snackbarColor, mensagemSnackBar);
        }
    }
};
</script>
<style lang="scss" scoped>
.h-90 {
    height: 90%;
}
</style>
