var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dropdown", attrs: { "data-html2canvas-ignore": "" } },
    [
      _c(
        "button",
        {
          staticClass: "dropbtn",
          on: {
            click: function($event) {
              _vm.showMenu = !_vm.showMenu
            }
          }
        },
        [
          _vm._v(" Ações "),
          _c("v-icon", { attrs: { color: "#04BDD7" } }, [
            _vm._v("mdi-chevron-down")
          ])
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showMenu,
              expression: "showMenu"
            }
          ],
          staticClass: "dropdown-content"
        },
        _vm._l(_vm.actions, function(action) {
          return _c(
            "a",
            {
              key: action.name,
              class: { disabled: action.disabled },
              on: {
                click: function($event) {
                  action.disabled ? null : _vm.executeAction(action.method)
                }
              }
            },
            [_vm._v(_vm._s(action.name))]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }