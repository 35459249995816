<template>
    <v-card class="pa-3 card-historico">
        <v-row>
            <v-col cols="6">
                <h4 class="bold">Historico de status</h4>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="bg-historico">
                    <ul v-for="(status, index) in historico" :key="index">
                        <li>
                            <strong>
                                {{
                                    status.dt_arquivo_movimento
                                        ? dtMovimentoFormat(
                                              status.dt_arquivo_movimento
                                          )
                                        : status.dt_historico
                                }}:
                            </strong>
                            {{ status.DESCRICAO }}
                            <v-icon
                                class="ml-3"
                                @click="
                                    (statusSelected = status),
                                        (dialogConfirm = true)
                                "
                                >mdi-trash-can-outline</v-icon
                            >
                        </li>
                    </ul>
                </div>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogConfirm" max-width="550">
            <v-card max-width="550">
                <v-card-title class="headline primary--text"
                    >Confirmação</v-card-title
                >
                <v-card-text>
                    Deseja realmente excluir o histórico?
                </v-card-text>
                <v-card-text>
                    {{
                        statusSelected.dt_arquivo_movimento
                            ? dtMovimentoFormat(
                                  statusSelected.dt_arquivo_movimento
                              )
                            : statusSelected.dt_historico
                    }}:
                    {{ statusSelected.DESCRICAO }}
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="dialogConfirm = false">Cancelar</v-btn>
                    <v-btn @click="deleteHistory(statusSelected.id_historico)"
                        >Confirmar</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        <Snackbar
            data-html2canvas-ignore
            v-if="feedbackSnackbar"
            :mostrarSnackbar="feedbackSnackbar"
            :corSnackbar="feedbackSnackbarColor"
            :mensagemSnackbar="mensagemfeedbackSnackbar"
            @fecharSnackbar="feedbackSnackbar = false"
        />
    </v-card>
</template>
<script>
import moment from 'moment';
import TituloService from '@/services/TituloService';
import Vue from 'vue';
export default {
    name: 'CardApontamento',
    components: {},
    props: {
        historico: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            tituloService: new TituloService(Vue.http, this.$store),
            dialogConfirm: false,
            statusSelected: {},
            feedbackSnackbar: false,
            feedbackSnackbarColor: '',
            mensagemfeedbackSnackbar: ''
        };
    },
    created() {
        moment.locale('pt-br');
    },
    methods: {
        dtMovimentoFormat(data) {
            return moment(data).format('DD/MM/YYYY HH:mm:ss');
        },
        deleteHistory(id) {
            this.tituloService
                .deleteHistoryStatus(id)
                .then(() => {
                    this.$emit('atualizaTitulo');
                    this.dialogConfirm = false;
                    this.feedbackSnackbar = true;
                    this.feedbackSnackbarColor = 'success';
                    this.mensagemfeedbackSnackbar =
                        'Histórico excluído com sucesso!';
                })
                .catch(() => {
                    this.feedbackSnackbar = true;
                    this.feedbackSnackbarColor = 'error';
                    this.mensagemfeedbackSnackbar =
                        'Erro ao excluir histórico!';
                });
        }
    }
};
</script>
<style lang="scss" scoped>
.card-historico {
    height: 100%;
}
.bg-historico {
    overflow: auto;
    height: 252px;
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            padding: 5px 0;
            border-bottom: 1px solid #ccc;
        }
    }
    &::-webkit-scrollbar {
        width: 6px; /* Largura da barra de rolagem */
    }

    /* Personalizar o fundo da barra de rolagem (parte que não se move) */
    &::-webkit-scrollbar-track {
        margin-left: 10px;
        background: #fff; /* Cor do fundo */
    }

    /* Personalizar a barra de rolagem móvel */
    &::-webkit-scrollbar-thumb {
        background: #888; /* Cor da barra de rolagem */
        border-radius: 5px; /* Border radius da barra de rolagem */
    }

    /* Personalizar a barra de rolagem quando o mouse está sobre ela */
    &::-webkit-scrollbar-thumb:hover {
        background: #555; /* Cor da barra de rolagem quando o mouse está sobre ela */
    }
}
</style>
